import React from "react";
import classNames from "classnames";

import { Footer } from "../components/Footer";

import * as styles from "../styles/pages/About.module.scss";
import { ReactComponent as PhotoPost } from "../images/photo-post.svg";
import { SEO } from "../components/SEO";
import { Link } from "gatsby";

// markup
const AboutPage = () => {
  return (
    <main className={classNames(styles.main)}>
      <SEO title="Not Found" />
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <PhotoPost className={styles.logo} />

          <p>This page can't be found</p>
          <p>
            Please go to the <Link to="/">home page</Link> to learn more about
            this project
          </p>
        </div>
        <Footer dark={true} center={false} full={false} />
      </div>
    </main>
  );
};

export default AboutPage;
