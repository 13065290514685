import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import socialImg from "../../images/social.jpg";

const prefixSite = (url, prefix) => {
  return url.substring(4) !== "http" ? `${prefix}${url}` : url;
};

const SEO = ({
  lang = "en",
  title,
  urlPath = "/",
  children,
  description,
  meta = [],
  image = socialImg,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;
  const url = `${site.siteMetadata.siteUrl}${urlPath}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s – ${site.siteMetadata.title}`}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: prefixSite(image, site.siteMetadata.siteUrl),
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: "@jthawme",
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          property: `twitter:url`,
          content: url,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: prefixSite(image, site.siteMetadata.siteUrl),
        },
      ].concat(meta)}
    >
      <link
        href="https://cloud.typography.com/7103754/717746/css/fonts.css"
        rel="stylesheet"
        type="text/css"
      />
      {children}
    </Helmet>
  );
};

export { SEO };
