import { graphql } from "gatsby";
import React, { useMemo } from "react";
import classNames from "classnames";

import { BackgroundImage } from "../components/BackgroundImage";
import { Footer } from "../components/Footer";
import { SEO } from "../components/SEO";

import * as styles from "../styles/pages/Point.module.scss";
import { ReactComponent as PhotoPost } from "../images/photo-post.svg";
import { IMAGES } from "../js/constants";
import { InternalExternalLink } from "../components/InternalExternalLink";

const PointTemplate = ({ data }) => {
  const { image: imageObj, content } = data;

  const image = useMemo(() => {
    return imageObj ? imageObj.frontmatter : IMAGES[0];
  }, [imageObj]);

  const { title, links } = useMemo(() => {
    return content.frontmatter;
  }, [content]);

  console.log(content.frontmatter);

  return (
    <main className={classNames(styles.main, { [styles.dark]: image.dark })}>
      <SEO title={title} />

      <BackgroundImage className={styles.bg} {...image} />

      <div className={styles.content}>
        <div className={styles.contentInner}>
          <PhotoPost className={styles.logo} />

          <ul>
            {links.map(({ link, text }) => {
              return (
                <li key={link}>
                  <InternalExternalLink to={link}>{text}</InternalExternalLink>
                </li>
              );
            })}
          </ul>
        </div>

        <Footer
          dark={image.dark}
          full={false}
          exclude={content.frontmatter.exclude ?? []}
        />
      </div>
    </main>
  );
};

export const query = graphql`
  query ($slug: String!, $image: String!) {
    content: markdownRemark(
      frontmatter: { slug: { eq: $slug } }
      fileAbsolutePath: { regex: "/points/" }
    ) {
      frontmatter {
        title
        slug
        links {
          link
          text
        }
        exclude
      }
    }
    image: markdownRemark(
      frontmatter: { title: { eq: $image } }
      fileAbsolutePath: { regex: "/images/" }
    ) {
      frontmatter {
        image
        dark
      }
    }
  }
`;

export default PointTemplate;
