import React, { useEffect, useState } from "react";

import classNames from "classnames";

import { loadImage } from "../../js/utils";
import * as styles from "./BackgroundImage.module.scss";

const BackgroundImage = ({ className, image, dark = false }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);

    loadImage(image).then(() => {
      setLoaded(true);
    });
  }, [image]);

  return (
    <div
      className={classNames(
        styles.wrapper,
        { [styles.dark]: dark, [styles.loaded]: loaded },
        className
      )}
    >
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  );
};

export { BackgroundImage };
