import React from "react";

import { Layout } from "./src/components/Layout";
import { registerBootlegVH } from "./src/js/utils";

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const onClientEntry = () => {
  // smoothscroll.polyfill();
  registerBootlegVH();
};
