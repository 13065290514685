import React from "react";
import { SEO } from "../SEO";

import "../../styles/global.scss";

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      {children}
    </>
  );
};

export { Layout };
