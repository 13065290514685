import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { BackgroundImage } from "../components/BackgroundImage";
import { Footer } from "../components/Footer";
import { IMAGES } from "../js/constants";

import * as styles from "../styles/pages/Home.module.scss";
import { graphql } from "gatsby";
import { InternalExternalLink } from "../components/InternalExternalLink";

// markup
const IndexPage = ({ data }) => {
  const { images } = data;

  const [imageIndex, setImageIndex] = useState(null);

  useEffect(() => {
    setImageIndex(Math.floor(Math.random() * images.edges.length));
  }, [images]);

  const image = useMemo(
    () =>
      images.edges[imageIndex]
        ? images.edges[imageIndex].node.frontmatter
        : IMAGES[0],
    [images, imageIndex]
  );

  return (
    <main className={classNames(styles.main, { [styles.dark]: image.dark })}>
      {imageIndex !== null && (
        <BackgroundImage className={styles.bg} {...image} />
      )}

      <div className={styles.content}>
        <div className={styles.contentInner}>
          <p>
            <strong>Active Beacons</strong> is a way ﬁnding system of sculptural
            totems designed by{" "}
            <InternalExternalLink to="https://galvinbrothers.co.uk/">
              Matthew Galvin
            </InternalExternalLink>{" "}
            in partnership with East Riding of Yorkshire Council and the
            University of Hull.
          </p>

          <p>
            The hand turned posts borrow from the existing language of coastal
            groynes, cairns and beacons to create iconic way markers for route
            mapping along the British coastal path and inland trails.
          </p>

          <p>
            Each beacon is equipped with a phone cradle to provide a ﬁxed
            position photography point giving the community the ability to
            monitor the changing coastline / landscape over time.
          </p>

          <p>
            To ﬁnd out more about this project or to commission your own beacons
            please contact us:{" "}
            <a href="mailto:mail@galvinbrothers.co.uk?subject=Active Beacons">
              mail@galvinbrothers.co.uk
            </a>
          </p>
        </div>
        <Footer dark={image.dark} center={false} />
      </div>
    </main>
  );
};

export const query = graphql`
  query HomeImages {
    images: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/images/" } }
    ) {
      edges {
        node {
          frontmatter {
            image
            title
            dark
          }
        }
      }
    }
  }
`;

export default IndexPage;
