import React from "react";
import classNames from "classnames";

import { Footer } from "../components/Footer";

import * as styles from "../styles/pages/About.module.scss";
import { ReactComponent as PhotoPost } from "../images/photo-post.svg";
import { SEO } from "../components/SEO";

// markup
const AboutPage = () => {
  return (
    <main className={classNames(styles.main)}>
      <SEO title="About" />
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <PhotoPost className={styles.logo} />

          <p>
            The photo post in front of you is part of a global community coastal
            monitor project and was developed in a partnership between the
            University of Hull and East Riding of Yorkshire Council.
          </p>
          <p>
            <span class={styles.title}>Become a citizen scientist</span>
            The project relies on your involvement; taking photographs from this
            and other ﬁxed point locations using your mobile phone allows us to
            capture how our dynamic world is changing over time.
          </p>
          <p>
            <span class={styles.title}>Social Media Sharing</span>
            By sharing your landscape photograph on your social media using the
            photo post’s hashtag you can help us monitor the changing coastline.
          </p>
          <p>
            Visit the University of Hull’s Active Learning to ﬁnd our more:
            <br />
            <a
              href="https://activelearning.hull.ac.uk"
              target="_blank"
              rel="noreferrer"
            >
              activelearning.hull.ac.uk
            </a>
          </p>
          <p>
            To commission photo posts for your region and join this global
            community project contact:
            <br />
            <a href="mailto:mail@galvinbrothers.co.uk?subject=Active Beacons">
              mail@galvinbrothers.co.uk?subject=Active Beacons
            </a>
          </p>
        </div>
        <Footer dark={true} center={false} full={false} />
      </div>
    </main>
  );
};

export default AboutPage;
